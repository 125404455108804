// This is the Pendo script modified to accept inputs
function excelpendo(userId, userEmail, userTitle, portal, customerId, customerName, salesForceId, purposeId) {
	var portalKey = '4a30a966-d72a-43cb-73f5-b02351424bc8';

	(function (apiKey) {
		(function (p, e, n, d, o) {
			var v, w, x, y, z;
			o = p[d] = p[d] || {};
			o._q = [];
			v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
			for (w = 0, x = v.length; w < x; ++w)(function (m) {
				o[m] = o[m] || function () {
					o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
				};
			})(v[w]);
			y = e.createElement(n);
			y.async = !0;
			y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
			z = e.getElementsByTagName(n)[0];
			z.parentNode.insertBefore(y, z);
		})(window, document, 'script', 'pendo');

		if (userId) { // Only call the initialize function if we have at least a userId
			pendo.initialize({
				visitor: {
					id: userId, // Required if user is logged in
					email: userEmail,
					title: userTitle,
					portal: portal
				},

				account: {
					id: customerId,
					name: customerName,
					salesforceId: salesForceId,
					purposeId: purposeId
				},
				disablePersistence: true
			});

			console.log(portal + ' Pendo Initialized:' + userId);
		}
	})(portalKey);
}

function change(userId, key) {
	pendo.apiKey = key;
	pendo.identify({ visitor: { id: userId}});
	pendo.loadGuides();
}
